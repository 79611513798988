.wrapper {
  background: #f8f9fa !important;
  padding: 1rem;
}
.qty {
  text-align: right;
}
.bold {
  font-weight: bold !important;
}
.orderBtn {
  background: #fc9005 !important;
  color: white !important;
  min-width: 10rem !important;
}
.deliveryTime {
  width: 60%;
}
