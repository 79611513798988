.App {
    font-family: Takeaway Sans,Avant Garde,Century Gothic,Helvetica,Arial,sans-serif;
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
    width: 100%;
    height: 100%;
    
}
* {
    margin: 0;
    padding: 0;
    }