.boxWrapper{
    min-height: 80vh;
}
.category{
    list-style: none;
    display: inline-block;
    margin-left: .3rem;
    //background: linear-gradient(to right,#f76f2a 50%,#e0e0e0 50%) no-repeat scroll right bottom/210% 100% #414549!important;
    border: none;
    padding: .4em .65em;
    border-radius: 4px!important;
    font-size: 12px;
    font-weight: 700;
    line-height: 1em;
    text-transform: uppercase;
    cursor: pointer;
    color:#9a9a9a;
    min-width: 50px !important;
    min-height: 30px !important;
    margin : 0.5rem !important;
   
}
.mainImg{
    position: relative;
    height: 100px  !important;
    width: 100px;
    padding: 0px !important;
    text-align: center;
    object-fit: cover;

}
.foodImg{
    display: flex;
    justify-content: center;

    align-items: center;   /* <---- NEW    */
   
    width: 100%;
    height: 100%; 
}
.categories{
    margin-top: 0.5rem  !important;
    margin-bottom: 0  !important;
    color: #FC9005;
    padding-left: 0.9rem;
    font-weight: 600;
}
.coverImg{
    position: relative;
}
.container{
    padding: 2.3rem;;
}
 .restaurantContent{
     
     background-color: rgba(000, 000, 000,0.7);
     height: 100%;
   
 }

 .restaurantContent h5,  .restaurantContent h6, .restaurantContent p{
     font-weight: bold;
     padding-left:2rem;
     color: #fff;
 }
 .restaurantContent:before{
    position: absolute;
    
    content: '';
    width: 10px;
    height: 240px;
    left: 3%;
    top: 10%;
    border-radius: 50px;
    background-color: #FC9005;
 }
 .avataHolder img{
     padding-bottom: 0.5rem;
     height: 90px  !important;
     width: 90px !important;
 }
 .avataHolder{
     position: relative;
     width: 100%;;
     text-align: center;
     justify-content: center;
 }
 .menuBar{
    box-shadow: none !important;
     background-image: url('../../../../assets/img/main_bg.jpg');
 }
 .menuBar span{
     font-weight: bold  !important;
 }
