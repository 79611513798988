.ResturantHolder{
    position: relative;
    background-color: #ffffff;
    background: url(../../../../assets/img/transparent-image-7.png) 150% 15% no-repeat;
}
.resturanImg{
    position: relative;
    height: 160px  !important;
    width: 160px;
    padding: 0px !important;
    object-fit: cover;
    @media screen and (max-width: 501px) {
        height: 100% !important;
        width: 100% !important;
      }
}
