.locationIcon{
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    transition: background-color 120ms ease-out 0s;
    margin-inline-end: 0.5rem;
    background: #ffbd2f;
}
.locationText{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-inline-end: 0.5rem;
    font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
 
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;

}
.locationWrapper{
    margin-top: 1rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;

    cursor: pointer;
    position: relative;
    color:#fff;
    max-width: 70%;
}

.location input{
    padding: 0.9rem 9rem 0.7rem 1.5rem  !important;
    background-color: #fff;
    border:none !important
}
.locationButton{
    border-radius: 0px !important;
    padding: 0.7rem 3rem 0.75rem 3rem  !important;
    background-color :#92bb18 !important;
    color: #fff  !important;
    box-shadow: none !important;
}
.searchBox{
    background: #fff !important;
    border: none !important;
  
    outline: none !important;
  
}
.searchInput{
    border: none !important;
    border-radius: 0px !important;
    outline: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}