.avatar{
    
    position: absolute !important;
    top: 0 !important;
    left:21% !important;
  
    text-align: center;
    z-index: 2;
    height: 100px !important;
    width: 100px !important;

}
.ResturantName{
    background: url(../../../../assets/img/pattern.png) 100% 100% no-repeat;
    position: absolute;
    padding: 4rem 2rem 4rem 2rem;
    right: 5%;
    top: 5%;
    width: 50%;
    color:  #92bb18 !important;
    z-index: -10;
    background-color: #F1F3F6;
    @media screen and (max-width: 501px) {
        position: relative;
        left: 0;
        padding: 2rem;;
        width: 100%;
        z-index: 10;
      }
}

