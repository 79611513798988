button {
  text-transform: capitalize !important;
}
.mainImg {
  position: relative;
  height: 100px !important;
  width: 100px;
  padding: 0px !important;
  text-align: center;
  object-fit: cover;
  cursor: pointer;
}
.foodImg {
  display: flex;
  justify-content: center;

  align-items: center; /* <---- NEW    */

  width: 100%;
  height: 100%;
}
.categories {
  margin-top: 0.5rem !important;
  margin-bottom: 0 !important;
  color: #ffbd2f !important;

  font-weight: 600;
}
.coverImg {
  position: relative;
}
.container {
  padding: 2.3rem;
}
@media (max-width: 800px) {
  .container {
    padding: 0 0.5rem 1rem 0 !important;
  }
  .reverse {
    padding-left: 1rem;
    flex-direction: column-reverse;
  }
  .container img {
    margin-left: 1rem !important;
  }
}
.restaurantContent {
  background-color: rgba(000, 000, 000, 0.7);
  height: 100%;
}

.restaurantContent h5,
.restaurantContent h6,
.restaurantContent p {
  padding-left: 2rem;
  color: #fff;
}
.restaurantContent button {
  color: #fff;
}
.restaurantContent:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 179px;
  left: 3%;
  top: 10%;
  border-radius: 50px;
  background-color: #fc9005;
}
.avataHolder img {
  padding-bottom: 0.5rem;
  height: 90px !important;
  width: 90px !important;
}
.avataHolder {
  position: relative;
  width: 100%;
}
.menuBar {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  background-color: #fff !important;
}
.menuBar span {
  font-weight: bold !important;
}
.addBtn {
  cursor: pointer;
}
.basketButt {
  background-color: #fc9005 !important;
  color: #fff !important;
}
.basketButt p {
  padding-top: 10px;
  font-weight: 600 !important;
}
.chip {
  margin-left: 2rem;
  background-color: #fff !important;
}
.boxWrapper {
  min-height: 80vh;
}
.RestauranInfo {
  margin-left: 2rem;
}
