.buttons button {
  padding: 3px !important;
  min-width: 35px !important;
  border: 1px solid #92bb18 !important;
}
.addBtn {
  background: #92bb18 !important;
  color: #fff !important;
}
.removeBtn {
  min-width: 40px !important;
}
.removeBtn svg {
  cursor: pointer;
  color: #92bb18 !important;
}
.qty {
  min-width: 2rem !important;
}
.productName {
  min-width: 7rem;
}
.orderBtn {
  background-color: #92bb18 !important;
  color: #fff !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
}
.cart {
  padding-top: 1rem !important;
}
form {
  width: 100%;
}
.btn {
  margin-top: 0.3rem;
}
