.download img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-top: 1rem;;
    z-index: 1;
}
.holder{
    position: relative;
    z-index: 1;
}
.downloadContent{
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
    @media screen and (max-width: 501px) {
        top:20%;
        left: 0;
        padding: 1rem;;
        transform: translate(0, 0);
      }
}
.appstore{
    margin-top: 1rem !important;
    background-color: #92bb18 !important;
    color : #fff !important;
    margin-left: 1rem !important;
}
.playstore{
    margin-top: 1rem !important;
    border-color: #fff !important;
    color: #fff  !important;
}