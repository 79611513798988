footer{
    background-color: #92BB18;
    color: #fff;
    padding: 0 1rem 1rem 1rem;

}
footer a{
    color: #fff !important;
}
footer img{
    width: 70%;
}
footer p:first-child{
    padding-top:2rem  !important;
}