.slider {
  position: relative;
  z-index: 1;
}
.slider img {
  min-height: 300px;
  object-fit: cover;
  width: 100%;
}

.sliderContent {
  color: white !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  @media screen and (max-width: 501px) {
    top: 5%;
    left: 0;
    padding: 1rem;
    transform: translate(0, 0);
  }
}
.sliderContent h4,
.sliderContent h6 {
  color: white !important;
}
.slider input {
  padding: 0.8rem !important;
  background-color: #fff;
  border: none !important;
  width: 100%;
}
.sliderButton {
  border-radius: 0px !important;
  padding: 0.7rem 3rem 0.75rem 3rem !important;
  background-color: #92bb18 !important;
  color: #fff !important;
}
.searchBox {
  background: #fff !important;
  border: none !important;
  min-width: 60% !important;
  outline: none !important;
}
.searchInput {
  border: none !important;
  border-radius: 0px !important;
  outline: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
