header{
    background: #92BB18;
    width: 100%;
}
header a{
    font-size: 1.3rem;
    color: #fff;
    text-decoration: none;
}
header .locale{
    margin-right: 10px;
    color: #fff  !important;
   
}
header .locale option{
    cursor: pointer;
   
}
header .locale svg{
    display: none !important;
}
.localeWrapper  div{
  
   padding-right: 0px  !important;
}
.localeWrapper  div:before{
    border:none !important;
}
header svg{
    font-size: 1.8rem !important;
    color: #fff !important;
}