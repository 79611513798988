a{
    text-decoration: none !important;
}
.wrapper{
    background-image: url('../../../../assets/img/bg-6.jpg');
    height: 100%;
}
.dishTitle{
    font-size: 20px;;
    font-weight: 600  !important;
    margin-top: 1rem  !important;
    color: #9a9a9a !important;
}
.dishPrice{
    margin-top: 0.5rem;
    color: #91b620 !important;
}

.card{
    max-width: 345px;
    margin-bottom:  15px;
    box-shadow: none !important;
    border-radius: 6% !important;
}
.media{
    height: 140px;
}