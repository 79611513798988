.headerWrapper {
  position: relative;

  padding-bottom: 3rem;

  background-color: #f1f1f1 !important;
}
.rateBtn {
  font-weight: bold;
  color: #fff !important;
  background-color: #ffbd2f !important;
}
