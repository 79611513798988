
    .wrapper{
        background: #f1f1f1;
        padding: 0.5rem 0 1.5rem 0;
        height: 100%;
        margin-top: -5px;;
    }

.homeTitle{
    margin-top: 4rem !important;
    color:#ffbd2f!important;
    font-weight: 700 !important;
}


.dividerHolder {
    position: relative;
    margin: 10px auto 10px;
    text-align: center;
    width:8%;
    color: #999 !important;
}
.divider:before{
        content: '';
        position: absolute;
        width: 80px;
        height: 1px;
        background: #999;
        top: 50%;
        right: -80px;
        opacity: .5;
    
}
.divider:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 1px;
    background: #999;
    top: 50%;
    right: auto;
    left: -80px;
    opacity: .5;

}